export enum FEATURE_TYPE {
  TOOLS = 'TOOLS',
  APP_BUILDER = 'APP_BUILDER',
  TOOL_CREATOR = 'TOOL_CREATOR',
  INSIGHTS = 'INSIGHTS',
  DASHBOARD = 'DASHBOARD',
}

export interface FeatureList {
  id: number;
  type: FEATURE_TYPE;
  isActive: boolean;
}

export interface Features {
  id: number;
  type: FEATURE_TYPE;
  enabled: boolean;
}
