import { create, StateCreator } from 'zustand';
import {
  ChartResponse,
  DefaultResponse,
  PromptData,
  Response,
  ResponseType,
  SocketEvents,
} from '@rangpt/types';
import { v4 as uuidv4 } from 'uuid';
import {
  defaultChatSlice,
  DefaultChatSliceProps,
} from '@rangpt/stores/defaultChatSlice.ts';

export interface CommandInterfaceSlice {
  chat: PromptData[];
  addCode: (index: number, value: string) => void;
  addLoadingAnswer: (prompt_id: string) => void;
  addResponse: (
    event: SocketEvents,
    type: ResponseType,
    isFinal?: boolean,
  ) => void;
  setChartCache: (
    event: SocketEvents,
    cachedChart: string,
    currentNode: number,
  ) => void;
  currentNode: number;
  setCurrentNode: (node: number) => void;
  clearChat: () => void;
  resetStore: () => void;
}

type res = Response & (ChartResponse & DefaultResponse);

const commandInterfaceSlice: StateCreator<
  DefaultChatSliceProps<PromptData> & CommandInterfaceSlice,
  [],
  [],
  CommandInterfaceSlice
> = set => ({
  chat: [],
  currentNode: 0,
  clearChat: () =>
    set(() => ({
      chat: [],
      currentPrompt: 0,
      currentNode: 0,
      loading: false,
    })),
  setCurrentNode: (node: number) =>
    set(() => ({
      currentNode: node,
    })),
  addLoadingAnswer: (prompt_id: string) =>
    set(state => {
      const prompt = state.chat.find(prompt => prompt.prompt_id === prompt_id);
      if (!prompt) return state;

      const currentPrompt = prompt.answer!;
      currentPrompt.push({
        id: '',
        type: 'answer',
        content: '',
        timestamp: 0,
        isLoading: true,
      });

      return state;
    }),
  addResponse: (event: SocketEvents, type: ResponseType, isFinal = false) =>
    set(state => {
      const prompt = state.chat.find(
        prompt => prompt.prompt_id === event.prompt_id,
      );
      if (!prompt) return state;

      const idKey = `${type}_id` as keyof typeof event;

      if (prompt[type]) {
        if (type === 'answer') {
          const response = prompt[type]?.find(r => r.id === event[idKey]);

          if (response) return state;
        }
      }

      const response = {
        id: (event[idKey] ?? uuidv4()) as string,
        type: type,
        content: event.text ?? event.chart_id ?? event.html_id,
        // timestamp: event.timestamp, @NOTE: For now we have a problem in the API with timestamp generation
        timestamp: Date.now(),
        isLoading: false,
      } as const;

      if (type === 'answer') {
        const currentPrompt = prompt[type]!;
        const lastResponseIndex = currentPrompt.length - 1;
        if (currentPrompt[lastResponseIndex]?.isLoading) {
          currentPrompt[lastResponseIndex] = response;
          if (!isFinal) {
            currentPrompt.push({
              id: '',
              type: type,
              content: '',
              timestamp: 0,
              isLoading: true,
            });
          }

          return { ...state, loading: !isFinal };
        }
      }

      if (type === 'chart' || type === 'code' || type === 'html') {
        if (!prompt[type]) {
          prompt[type] = [response] as [res];

          console.debug('length', prompt[type]!.length - 1);

          return { ...state, currentNode: prompt[type]!.length - 1 };
        }
        const item = prompt[type]!.find(item => item.id === response.id);

        if (item) return state;

        prompt[type]!.push(response as res);

        return { ...state, currentNode: prompt[type]!.length - 1 };
      }

      return state;
    }),
  setChartCache: (
    event: SocketEvents,
    cachedChart: string,
    currentNode: number,
  ) =>
    set(state => {
      if (currentNode < 0) return state;
      const arr = state.chat;
      const prompt = arr.find(prompt => prompt.prompt_id === event.prompt_id);
      if (!prompt?.chart) return state;

      if (prompt.chart[currentNode].id === event.chart_id) {
        prompt.chart[currentNode].cachedChart = cachedChart;
      }

      return { chat: arr };
    }),
  addCode: (index: number, value: string) =>
    set(state => {
      const code = state.chat[index].code!;

      code.push({
        type: 'code',
        id: uuidv4(),
        content: value,
        timestamp: Date.now(),
      });

      return state;
    }),
  resetStore: () => set({ chat: [], currentNode: 0 }),
});

const useCommandInterfaceStore = create<
  DefaultChatSliceProps<PromptData> & CommandInterfaceSlice
>()((...a) => ({
  ...defaultChatSlice(...a),
  ...commandInterfaceSlice(...a),
}));

export default useCommandInterfaceStore;
