import useDashboardStore from '@rangpt/stores/useDashboardStore.ts';
import useAiraChatStore from '@rangpt/stores/useCommandInterfaceStore.ts';
import { chartToListener } from '@rangpt/lib/utils.ts';
import { useEffect } from 'react';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { DashboardClient, UserClient } from '@packages/api';
import { useAuth } from '@packages/hooks';
import { Chart } from '@rangpt/types/dashboard.types.ts';
import { useShallow } from 'zustand/react/shallow';
import { toast } from 'react-toastify';
import {
  defaultLightToastOptions,
  defaultToastErrorToastOptions,
} from '@packages/ui';
import { useSocket } from '@rangpt/hooks/useSocket.ts';

const useDashboard = () => {
  const { currentUser } = useAuth();
  useSocket();

  const dashboardStore = useDashboardStore();
  const { dashboard, addGraph, removeGraph, setDashboard } = dashboardStore;

  const { chat, currentPrompt, currentNode } = useAiraChatStore(
    useShallow(state => ({
      chat: state.chat,
      currentPrompt: state.currentPrompt,
      currentNode: state.currentNode,
    })),
  );

  const userApi = new UserClient(0, currentUser!.sessionToken);
  const dashboardApi = new DashboardClient(currentUser!.sessionToken);
  const queryClient = new QueryClient();

  const { data: dashboardData, isLoading } = useQuery<Chart[]>(
    {
      queryKey: ['dashboardList'],
      queryFn: () => userApi.getDashboard(currentUser!.username),
      refetchOnMount: dashboard.length === 0,
      retryOnMount: dashboard.length === 0,
      retry: 0,
    },
    queryClient,
  );

  const deleteChartMutation = useMutation(
    {
      mutationFn: (id: string) => dashboardApi.deleteDashboardChart(id),
      onSuccess: () => {
        toast.success(
          'Chart removed from you dashboard!',
          defaultLightToastOptions,
        );
      },
      onError: error => {
        console.debug('~ error on delete chart', error);
        toast.error(
          'Error removing chart from dashboard.',
          defaultToastErrorToastOptions,
        );
      },
    },
    queryClient,
  );

  const saveChartMutation = useMutation(
    {
      mutationFn: (payload: Chart) => dashboardApi.saveChart(payload),
      onSuccess: () => {
        toast.success(
          'Chart added on your dashboard!',
          defaultLightToastOptions,
        );
      },
      onError: error => {
        console.debug('~ error on delete chart', error);
        toast.error(
          "Couldn't save chart to dashboard.",
          defaultToastErrorToastOptions,
        );
      },
    },
    queryClient,
  );

  useEffect(() => {
    if (dashboardData && dashboard !== dashboardData) {
      setDashboard(dashboardData);
    }
  }, [dashboardData]);

  const addGraphToDashboard = (graph: Chart) => {
    addGraph(graph);

    sendChartToListener();
    saveChartToDashboardHistory(graph);
  };

  // @TODO: pass the removeGraph to the onSuccess of mutation
  const deleteGraph = (index: number) => {
    deleteChartMutation.mutate(dashboard[index].id);
    removeGraph(index);
  };

  const sendChartToListener = () => {
    const data = chat[currentPrompt];

    if (!data.code || !data.code[currentNode]) return;

    const payload = {
      code_id: data.code[currentNode].id,
      chat_id: data.chat_id,
      prompt_id: data.prompt_id,
      text: data.code[currentNode].content,
      timestamp: data.code[currentNode].timestamp,
    };

    chartToListener(payload)
      .then(data => console.debug(data))
      .catch(e => console.error(e));
  };

  const saveChartToDashboardHistory = (chart: Chart) => {
    saveChartMutation.mutate(chart);
  };

  return {
    ...dashboardStore,
    isLoading,
    deleteGraph,
    addGraphToDashboard,
    sendChartToListener,
    saveChartToDashboardHistory,
  };
};

export default useDashboard;
