import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { API_URL, AuthClient, AuthUser, LoginRes } from '@packages/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '@hooks/utils/queryClient.ts';

const MINIMUM_LOADING_TIME = 1500; // ms

export interface AuthContextState {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  logOut: () => void;
  currentUser: AuthUser;
  setCurrentUser: (user: AuthUser) => void;
  loadingTokenUser: boolean;
  startSessionWs: (userId: string) => void;
  wsRef: WebSocket | null;
}

export const AuthContext = createContext<AuthContextState>({
  isAuthenticated: false,
  setIsAuthenticated: () => null,
  logOut: () => null,
  currentUser: null,
  setCurrentUser: () => {},
  loadingTokenUser: false,
  startSessionWs: _ => {},
  wsRef: null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
  const [loadingTokenUser, setLoadingTokenUser] = useState(false);
  const wsRef = useRef<WebSocket | null>(null);

  const authClient = new AuthClient();

  const { data, isLoading, isError } = useQuery<LoginRes>(
    {
      queryKey: ['autoLogin'],
      queryFn: () => authClient.autoLogin() as Promise<LoginRes>,
      refetchOnMount: false,
      retryOnMount: false,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
    queryClient,
  );

  const logOutMutation = useMutation(
    {
      mutationFn: () => authClient.logOut(currentUser?.sessionToken),
      onSuccess: () => {
        console.debug('logOut');
        setIsAuthenticated(false);
        wsRef.current?.close();
        setTimeout(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('owner');
        }, 1000);
      },
      onError: err => {
        console.debug('unexpected error occurred.');
        console.warn(err);
      },
    },
    queryClient,
  );

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (isLoading) {
      setLoadingTokenUser(true);
      return;
    }

    if (!token || isError || !data) {
      setLoadingTokenUser(false);
      setIsAuthenticated(false);
      return;
    }

    handleAuthentication(data, token);
  }, [data, isLoading, isError]);

  const logOut = () => {
    logOutMutation.mutate();
  };

  async function handleAuthentication(userData: LoginRes, token: string) {
    const startTime = Date.now();

    setCurrentUser({
      username: userData.username,
      isAdmin: userData.isAdmin,
      sessionToken: token,
      owner: userData.owner,
      id: userData.id,
    });

    // Ensure minimum loading time for better UX
    const elapsed = Date.now() - startTime;
    const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsed);

    await new Promise(resolve => setTimeout(resolve, remainingTime));

    setLoadingTokenUser(false);
    setIsAuthenticated(true);

    // Initialize websocket connection
    startSessionWs(userData.id);
  }

  function startSessionWs(userId: string) {
    if (!userId) return;
    const ws = new WebSocket(`${API_URL}?user=${userId}`);

    ws.onopen = () => {
      console.log('Connected to the Session server!');
    };

    ws.onclose = () => {
      console.log('Disconnected from the session server!');
    };

    wsRef.current = ws;
  }

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      logOut,
      currentUser,
      setCurrentUser,
      loadingTokenUser,
      startSessionWs,
      wsRef: wsRef.current,
    }),
    [isAuthenticated, currentUser, loadingTokenUser, setCurrentUser, wsRef],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
