import * as React from 'react';
import AiraLogo from '@public/aira-main-logo.png';
import AiraFavicon from '@public/aira-favicon.png';

import { NavMain } from './nav-main';
import { NavUser } from './nav-user';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from './ui/sidebar';
import { SideBarData } from '@ui/sidebar/SideBarData.tsx';
import { cn } from '@shadcn/lib/utils.ts';

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { toggleSidebar, open } = useSidebar();
  const data = SideBarData();

  return (
    <Sidebar
      collapsible="icon"
      variant="inset"
      className="shadow-2xl py-10 pb-5"
      {...props}
    >
      <SidebarHeader className="mb-8">
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <button className={cn('p-0 h-24 !w-48')} onClick={toggleSidebar}>
                <img
                  src={open ? AiraLogo : AiraFavicon}
                  alt="Aira Tecnologies"
                  className="aspect-auto h-full w-full"
                />
              </button>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent className="gap-8">
        <NavMain items={data.navMain} />
        {/*<NavRecent paths={data.paths} />*/}
        <SidebarGroup className="mt-auto">
          <SidebarGroupContent>
            {/*<SidebarMenu>*/}
            {/*  {data.navSecondary.map(item => (*/}
            {/*    <SidebarMenuItem key={item.title}>*/}
            {/*      <SidebarMenuButton asChild size="sm">*/}
            {/*        {!item.url ? (*/}
            {/*          <button>*/}
            {/*            <item.icon />*/}
            {/*            <span>{item.title}</span>*/}
            {/*          </button>*/}
            {/*        ) : (*/}
            {/*          <Link*/}
            {/*            to={item.url}*/}
            {/*            from="/"*/}
            {/*            target="_blank"*/}
            {/*            rel="noopener noreferrer"*/}
            {/*          >*/}
            {/*            <item.icon />*/}
            {/*            <span>{item.title}</span>*/}
            {/*          </Link>*/}
            {/*        )}*/}
            {/*      </SidebarMenuButton>*/}
            {/*    </SidebarMenuItem>*/}
            {/*  ))}*/}
            {/*</SidebarMenu>*/}
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <NavUser user={data.user} />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
}
