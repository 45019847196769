import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import ChartComponent from '@components/TestTab/chart/ChartComponent.tsx';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin,
);

export const ChartContainer = () => {
  return (
    <div className="w-full max-h-full max-w-[80rem] flex flex-col items-start justify-center relative overflow-scroll bg-white rounded-lg shadow-lg">
      <ChartComponent />
    </div>
  );
};

// {tooltipInfo.show && (
//   <div
//     className="absolute bg-white p-2 rounded shadow-lg border border-gray-200 max-w-56 text-xs"
//     style={{
//       left: tooltipInfo.x,
//       top: tooltipInfo.y - 30,
//       zIndex: 1000,
//       pointerEvents: 'none',
//     }}
//   >
//     {tooltipInfo.content}
//   </div>
// )}
