import { FormEvent, useEffect } from 'react';
import { useAuth } from '@packages/hooks';
import { Navigate } from '@tanstack/react-router';
import {
  AuthProgress,
  defaultToastErrorToastOptions,
  Login,
} from '@packages/ui';
import { LoginRes } from '@api/types';
import { useMutation } from '@tanstack/react-query';
import { AuthClient } from '@api/client';
import { flowChartStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { useRoutinesStore } from '@app/stores/routine.store.ts';
import useDashboardStore from '@rangpt/stores/useDashboardStore.ts';
import useCommandInterfaceStore from '@rangpt/stores/useCommandInterfaceStore.ts';
import { toast } from 'react-toastify';

export const LoginPage = () => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUser,
    loadingTokenUser,
    startSessionWs,
  } = useAuth();
  const { resetsTore: resetFlowChart } = flowChartStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );
  const { resetsTore: resetRoutines } = useRoutinesStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );
  const { resetsTore: resetDashboard } = useDashboardStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );

  const { resetsTore: resetChat } = useCommandInterfaceStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );

  useEffect(() => {
    resetRoutines();
    resetFlowChart();
    resetDashboard();
    resetChat();
  }, []);

  const authClient = new AuthClient();

  const loginMutation = useMutation({
    mutationFn: ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }) => authClient.login({ username, password }),
    onSuccess: (data: LoginRes) => {
      if (data.owner) {
        localStorage.setItem('owner', data.owner);
      } else {
        localStorage.removeItem('owner');
      }
      if (data.token) {
        localStorage.removeItem('token');
        localStorage.setItem('token', data.token);
      }

      // @TODO: maybe add data validation here, as this state needs all of this info
      setCurrentUser({
        username: data.username,
        isAdmin: data.isAdmin,
        sessionToken: data.token,
        owner: data.owner ?? '',
        id: data.id,
      });
      startSessionWs(data.id);
      setTimeout(() => {
        setIsAuthenticated(true);
      }, 500);
    },
    onError: error => {
      toast.error(error.message, defaultToastErrorToastOptions);
    },
  });

  if (loadingTokenUser) {
    return <AuthProgress />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData) as { user: string; pass: string };

    loginMutation.mutate({ username: data.user.trim(), password: data.pass });
  };

  return (
    <div className="w-screen h-screen flex bg-neutral-100 p-4">
      <Login.ShowCase />
      <Login.Container>
        <Login.Form submit={submit} isLoading={loginMutation.isPending} />
      </Login.Container>
    </div>
  );
};
