export const LoginShowCase = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-12 h-full w-1/2 rounded-2xl xl:p-2 p-4 opacity-80 shadow-lg bg-white relative">
      <img
        src="/aira-main-logo.png"
        alt="Aira entry speech"
        className=" w-1/5"
      />

      <img
        src="/undraw_visionary_technology.svg"
        alt="Aira entry speech"
        className=" w-2/4"
      />

      <section className="max-w-[580px] w-full flex flex-col gap-6 items-center text-center">
        <h1 className="text-dark-1 text-2xl font-bold">
          Naavik is reimagining what telecom can be in a software-driven world.
        </h1>
        <span className="text-gray-600 text-sm">
          Aira Naavik is the first platform to seamlessly combine GenAI with
          traditional AI to solve impactful use cases for network operators.
        </span>
      </section>
      {/*<div className="flex gap-2 absolute bottom-2">*/}
      {/*  <span className="w-4 h-2 cursor-pointer rounded-xl bg-red-400"></span>*/}
      {/*  <span className="w-4 h-2 cursor-pointer rounded-xl bg-gray-300"></span>*/}
      {/*  <span className="w-4 h-2 cursor-pointer rounded-xl bg-gray-300"></span>*/}
      {/*  <span className="w-4 h-2 cursor-pointer rounded-xl bg-gray-300"></span>*/}
      {/*</div>*/}
    </section>
  );
};
