import { flowChartStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { FlowChartNode, FlowChartState } from '@app/types';
import { generateShapeId } from '@app/lib/utils';
import { Connection, Edge, EdgeChange, NodeChange } from '@xyflow/react';
import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useRoutines } from '@app/hooks/useRoutines.ts';

const flowchartStoreSelector = (state: FlowChartState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  setNodes: state.setNodes,
  id: state.id,
  updateNode: state.updateNode,
  setSelectedNode: state.setSelectedNode,
});

export const useFlowChart = () => {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    setNodes,
    id,
    updateNode,
    setSelectedNode,
  } = flowChartStore(useShallow(flowchartStoreSelector));
  const { updateRoutine, currentRoutine, setCurrentRoutine } = useRoutines();

  const handleAddNode = (args: Partial<FlowChartNode>) => {
    setNodes([
      ...nodes,
      {
        ...args,
        id: generateShapeId(nodes),
        style: { color: '#000' },
        position: { x: 0, y: 0 },
      } as FlowChartNode,
    ]);
  };

  const debouncedApiUpdated = useDebouncedCallback((node?: FlowChartNode) => {
    setCurrentRoutine({
      ...currentRoutine,
      flowChart: {
        nodes: node ? [...nodes, node] : nodes,
        edges: edges,
      },
    });

    if (!currentRoutine.name) return;

    updateRoutine.mutate({
      id: currentRoutine.id,
      flowChart: {
        nodes: node ? [...nodes, node] : nodes,
        edges,
      },
    });
  }, 10000);

  const debouncedUpdateNode = useDebouncedCallback(
    (updatedNodes: FlowChartNode[]) => {
      console.debug('running debounced node updated');

      updateRoutine.mutate({
        id: currentRoutine.id,
        flowChart: {
          nodes: updatedNodes,
          edges,
        },
      });
    },
    1000,
  );

  const handleOnNodesChange = useCallback(
    (changes: NodeChange<FlowChartNode>[]) => {
      onNodesChange(changes);

      debouncedApiUpdated();
    },
    [onNodesChange, debouncedApiUpdated],
  );

  const handleOnEdgesChange = useCallback(
    (changes: EdgeChange<Edge>[]) => {
      onEdgesChange(changes);
      debouncedApiUpdated();
    },
    [onEdgesChange, debouncedApiUpdated],
  );

  const handleUpdateNode = useCallback(
    (node: Partial<FlowChartNode>) => {
      updateNode(node);

      if (!currentRoutine.name) return;

      const updatedNodes = nodes.map(fcNode =>
        fcNode.id === node.id ? { ...fcNode, ...node } : fcNode,
      );
      debouncedUpdateNode(updatedNodes);
    },
    [updateNode],
  );

  const handleOnConnect = useCallback(
    (connection: Connection) => {
      console.debug(connection);
      onConnect(connection);
    },
    [onConnect, debouncedApiUpdated],
  );

  return {
    nodes,
    edges,
    onNodesChange: handleOnNodesChange,
    onEdgesChange: handleOnEdgesChange,
    onConnect: handleOnConnect,
    handleAddNode,
    updateNode: handleUpdateNode,
    currentRoutine,
    id,
    setSelectedNode,
  };
};
