import useSessionStore from '@rangpt/stores/useSessionStore.ts';
import { v4 as uuidv4 } from 'uuid';
import useCommandInterfaceStore from '@rangpt/stores/useCommandInterfaceStore.ts';
import useSocketStore from '@rangpt/stores/useSocketStore.ts';
import { LoginRes, SessionAPI } from '@packages/api';

const useSession = () => {
  const { socket, setConfig, config, setForceReconnect } = useSocketStore();
  const {
    setInsights,
    session,
    changeCurrentSession,
    addSession,
    clearSessions,
    removeSession,
    updateSessionName,
    tools,
    setTools,
    insights,
  } = useSessionStore();

  const commandInterfaceStore = useCommandInterfaceStore();

  // Util
  const sessionApi = new SessionAPI();

  const sessionHasData = commandInterfaceStore.chat.length > 0;

  // Tools

  async function createSessionIfNeeded() {
    if (!sessionHasData) {
      await sessionApi.createSession({
        id: config.chat_id,
        updatedAt: Date.now(),
        name: null,
        isActive: true,
      });
    }
  }

  const updateTools = ({
    index,
    status,
  }: {
    index: number;
    status: boolean;
  }) => {
    if (!tools) return;

    tools.functions[index].is_enabled = status;

    const json = JSON.stringify(tools);

    setTools(json);

    socket?.emit('update_tools', {
      timestamp: Date.now(),
      chat_id: config.chat_id,
      username: config.username,
      tools: json,
      origin: 'aira_chat',
    });
  };

  const deleteTool = (index: number) => {
    if (!tools) return;

    tools.functions.splice(index, 1);

    const json = JSON.stringify(tools);

    socket?.emit('delete_tool', {
      timestamp: Date.now(),
      chat_id: config.chat_id,
      username: config.username,
      tools: json,
      origin: 'aira_chat',
    });
  };

  // Session

  function initialSession(data: LoginRes) {
    const chat_id = uuidv4();
    setConfig({
      username: data.username,
      chat_id,
      database: {},
    });

    addSession({
      session: {
        id: chat_id,
        name: 'Empty Session',
        date: new Date().toISOString(),
      },
      order: 'START',
    });

    changeCurrentSession(chat_id);
  }

  function newSession() {
    if (!sessionHasData) {
      return;
    }

    socket?.disconnect();

    const chat_id = uuidv4();

    setConfig({
      ...config,
      chat_id,
    });

    addSession({
      session: {
        id: chat_id,
        name: null,
        date: new Date().toISOString(),
      },
      order: 'START',
    });

    changeCurrentSession(chat_id);

    setForceReconnect(true);

    commandInterfaceStore.clearChat();

    const emptySession = session.sessionList.find(
      session => session.name === 'Empty Session',
    );

    if (emptySession) {
      removeSession(emptySession.id);
    }
  }

  function selectSession(id: string) {
    sessionApi
      .getSessionById(id)
      .then(res => {
        if (!sessionHasData) {
          removeSession(session.currentSession.id);
        }

        setConfig({
          ...config,
          chat_id: id,
        });

        const selectedSession = session.sessionList.find(
          session => session.id === id,
        )!;

        changeCurrentSession(selectedSession.id);

        setForceReconnect(true);

        if (res.commandInterface) {
          commandInterfaceStore.setChat(res.commandInterface.chat);
          commandInterfaceStore.setCurrentNode(0);
          commandInterfaceStore.setCurrentPrompt(
            res.commandInterface.currentPrompt,
          );
        } else {
          commandInterfaceStore.clearChat();
        }

        if (res.dashboard) {
          // const charts = res.dashboard.charts.map(chart => ({
          //   timestamp: chart.updatedAt,
          //   chart_id: chart.chart_id,
          //   code_id: chart.code_id,
          //   prompt_id: chart.prompt_id,
          //   code: chart.code,
          //   isHTML: chart.isHTML,
          // }));
          console.debug(
            '~ running select session logic, here would update dashboard',
            res.dashboard,
          );
          // dashboardStore.setDashboard({
          //   charts,
          //   updated: res.dashboard.updatedAt,
          // });
        } else {
          // dashboardStore.cleanUpDashboard();
        }

        const emptySession = session.sessionList.find(
          session => session.name === 'Empty Session',
        );

        if (emptySession) {
          removeSession(emptySession.id);
        }
      })
      .catch(e => console.error(e));
  }

  function fullCleanApplication() {
    socket?.disconnect();
    commandInterfaceStore.clearChat();
    clearSessions();
  }

  function disableSession({ id }: { id: string }) {
    const remainingSessions = session.sessionList.filter(
      session => session.id !== id,
    );
    removeSession(id);

    sessionApi
      .updateSession({ isActive: false, id })
      .then(res => {
        console.debug(res);

        if (remainingSessions.length > 0) {
          console.debug('still has prompt');
          selectSession(remainingSessions[0].id);
          return;
        }
        newSession();
      })
      .catch(err => console.error(err));
  }

  function addCodeReview(payload: Record<string, string | number | boolean>) {
    sessionApi
      .postCodeReview(payload)
      .then(res => console.log(res))
      .catch(res => console.log(res));
  }

  return {
    session,
    newSession,
    selectSession,
    fullCleanApplication,
    deleteTool,
    updateTools,
    disableSession,
    addSession,
    addCodeReview,
    initialSession,
    tools,
    setTools,
    insights,
    setInsights,
    updateSessionName,
    createSessionIfNeeded,
  };
};

export default useSession;
