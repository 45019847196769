import MaleAvatar from '@public/undraw_male_avatar.svg';
import {
  LayoutDashboard,
  Send,
  Settings,
  Settings2,
  TerminalIcon,
  WorkflowIcon,
} from 'lucide-react';
import { LifeBuoy } from 'react-feather';
import { useAuth } from '@packages/hooks';

type NavItem = {
  title: string;
  url: string | null;
  icon: any;
  isActive?: boolean;
  items?: {
    title: string;
    url: string;
  }[];
};

type Path = {
  name: string;
  url: string;
  icon: any;
};

type NavigationConfig = {
  user: {
    name: string;
    email: string;
    avatar: any;
  };
  navMain: NavItem[];
  navSecondary: NavItem[];
  paths: Path[];
};

export const SideBarData = (): NavigationConfig => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return {
      user: {} as any,
      navMain: [],
      navSecondary: [],
      paths: [],
    };
  }

  return {
    user: {
      name: currentUser.username.split('@')[0],
      email: currentUser.username,
      avatar: MaleAvatar,
    },
    navMain: [
      {
        title: 'My Dashboard',
        url: 'dashboard',
        icon: LayoutDashboard,
        isActive: true,
        items: [
          // {
          //   title: 'Team Dashboard',
          //   url: '#',
          // },
        ],
      },
      {
        title: 'App builder',
        url: 'app',
        icon: WorkflowIcon,
        items: [],
      },
      {
        title: 'Command Interface',
        url: 'commandInterface',
        icon: TerminalIcon,
        items: [
          // {
          //   title: 'Current Chat',
          //   url: '#',
          // },
        ],
      },
      {
        title: 'Management',
        url: null,
        icon: Settings2,
        items: [
          {
            title: 'Account',
            url: 'management',
          },
          {
            title: 'Data base',
            url: 'management/database',
          },
          {
            title: 'Capability',
            url: 'management/capability',
          },
          {
            title: 'Memory',
            url: 'management/memory',
          },
        ],
      },
    ],
    navSecondary: [
      {
        title: 'Aira Support',
        url: 'https://www.aira-technology.com/products/naavik/',
        icon: LifeBuoy,
      },
      {
        title: 'Feedback',
        url: 'https://www.aira-technology.com/contact-us/',
        icon: Send,
      },
      {
        title: 'Settings',
        url: null,
        icon: Settings,
      },
    ],
    paths: [
      // {
      //   name: 'Named Chat',
      //   url: '#',
      //   icon: TerminalIcon,
      // },
      // {
      //   name: 'Named Routine',
      //   url: '#',
      //   icon: WorkflowIcon,
      // },
      // {
      //   name: 'Data base',
      //   url: '#',
      //   icon: DatabaseIcon,
      // },F
    ],
  };
};
