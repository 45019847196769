import { create } from 'zustand';
import { ApplicationUser } from '@packages/api/types';

interface AccManagementStoreState {
  users: ApplicationUser[];
  setUsers: (users: ApplicationUser[]) => void;
}

const useAccManagementStore = create<AccManagementStoreState>(set => ({
  users: [],
  setUsers: users => set({ users }),
}));

export default useAccManagementStore;
