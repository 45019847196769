import { token } from '@packages/api';
import { LoginRes } from '@api/types';
import { API_URL } from '@api/configs.ts';
import { fetchWithErrorHandling } from '@api/utils.ts';

const path = `${API_URL}/auth`;

export class AuthClient {
  public defaultHeaders: Record<string, string>;

  constructor(userToken?: string) {
    const sanitizedToken = userToken ?? token;
    this.defaultHeaders = {
      authorization: sanitizedToken.trim().replace(/,/g, ''),
      'Content-Type': 'application/json',
    };
  }

  public async login(payload: { username: string; password: string }) {
    return await fetchWithErrorHandling<LoginRes>(`${path}/login`, {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify(payload),
    });
  }

  public async logOut(token?: string) {
    return await fetchWithErrorHandling<void>(`${path}/logout`, {
      method: 'POST',
      headers: {
        ...this.defaultHeaders,
        authorization: token ?? this.defaultHeaders.authorization,
      },
    });
  }

  public async autoLogin() {
    return await fetchWithErrorHandling<LoginRes>(`${path}/login/auto`, {
      method: 'POST',
      headers: this.defaultHeaders,
    });
  }
}
