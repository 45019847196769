import { Line } from 'react-chartjs-2';
import { generateChartOptions } from './chartConfig.ts';
import { MouseEvent, useMemo, useRef } from 'react';
import { useChart } from '@app/hooks/useChart.ts';
import { useShallow } from 'zustand/react/shallow';
import { UiStore, useChartStore } from '@app/stores';
import { Chart, ChartDataset } from 'chart.js';
import { useDebouncedCallback } from 'use-debounce';

const ChartComponent = () => {
  const chartRef = useRef<Chart<'line'> | null>(null);
  const {
    labels,
    datasets,
    annotations,
    addAnnotation,
    setToolTip,
    setDataset,
  } = useChart();
  const { showAnnotations, setShowAnnotations } = UiStore(
    useShallow(state => ({
      showAnnotations: state.showAnnotations,
      setShowAnnotations: state.setShowAnnotations,
    })),
  );

  const chartData = useMemo(
    () => ({
      labels,
      datasets,
    }),
    [labels, datasets],
  );

  const options = generateChartOptions({
    dataset: datasets,
    setToolTip,
    setShowAnnotations,
    showAnnotations,
    annotations,
  });

  const updateLineCallback = useDebouncedCallback(() => {
    const { annotations } = useChartStore.getState();

    if (annotations.length === 0) {
      setDataset([
        {
          label: 'Default',
          data: Array.from({ length: 25 }, () => 1),
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          yAxisID: 'y',
        },
      ]);
      return;
    }

    const sortedAnnotations = [...annotations].sort(
      (a, b) => a.xValue - b.xValue,
    );

    // Create array for all X points (0-24)
    const data = Array.from({ length: 25 }, (_, index) => {
      // Find if we have an annotation for this X value
      const annotation = sortedAnnotations.find(
        a => Math.round(a.xValue) === index,
      );
      // Return the Y value if we have an annotation, otherwise return 1
      return annotation ? annotation.yValue : 1;
    });

    const newDataset: ChartDataset<'line'> = {
      label: 'Default',
      data,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
      yAxisID: 'y',
    };

    setDataset([newDataset]);
  }, 1000);

  const onClickChart = (e: MouseEvent<HTMLCanvasElement>) => {
    const chart = chartRef.current;
    if (!chart) return;

    // Get relative position in the chart
    const rect = chart.canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Convert to chart values
    const xValue = chart.scales.x.getValueForPixel(x);
    const yValue = chart.scales.y.getValueForPixel(y);

    if (!xValue || !yValue) return;

    const newAnnotation = {
      type: 'point',
      xValue,
      yValue,
      backgroundColor: 'rgba(255, 99, 132, 0.25)',
      radius: 8,
      borderWidth: 2,
      borderColor: 'red',
      cursor: 'pointer',
      drawTime: 'afterDraw',
      z: 100,
    };

    addAnnotation(newAnnotation);
    updateLineCallback();
  };

  return (
    <div className="w-full px-4">
      <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
        <div className="absolute top-0 left-0 w-full h-full">
          <Line
            ref={chartRef}
            data={chartData}
            options={options}
            id="networkChart"
            redraw={true}
            onClick={onClickChart}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
