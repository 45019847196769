import { ReactNode } from 'react';
import { AnimatedText } from '../AnimatedText';

export function LoginContainer({ children }: { children: ReactNode }) {
  return (
    <section className="h-full w-1/2 flex flex-col items-center">
      {/*<Button variant="ghost" className="self-end">*/}
      {/*  <HelpCircle className="w-5 h-5" />*/}
      {/*</Button>*/}

      <section className="w-1/2 my-auto flex flex-col items-center gap-20">
        <AnimatedText
          text="Welcome to Naavik."
          className="text-dark-1 text-2xl font-medium"
        />
        {children}
      </section>

      {/*<a href="#" className="text-dark-300 text-xs hover:underline self-end">*/}
      {/*  Contact us*/}
      {/*</a>*/}
    </section>
  );
}
