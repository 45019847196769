import { FormEvent, ReactElement, useState } from 'react';
import { useAccManagement } from '@management/hooks';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@packages/ui/shadcn/ui/dialog.tsx';
import { Input } from '@packages/ui/shadcn/ui/input.tsx';
import { Switch } from '@packages/ui/shadcn/ui/switch.tsx';
import { Checkbox } from '@packages/ui/shadcn/ui/checkbox.tsx';
import { transformForInput } from '@management/utils/formatText.ts';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { Loader2 } from 'lucide-react';
import { User, UserFormData } from '@packages/api/types';

type UserModalProps =
  | {
      trigger: ReactElement;
      type: 'CREATE';
      userData?: never;
    }
  | {
      trigger: ReactElement;
      type: 'UPDATE';
      userData: User;
    };

/*
 * Used for user creation and update, consumes the useAccManagement hook
 * */
export const UserModal = ({ trigger, type, userData }: UserModalProps) => {
  const {
    users,
    clientFeatures,
    createUserMutation,
    updateUserMutation,
    dialogIsOpen,
    setDialogIsOpen,
  } = useAccManagement();
  const [formError, setFormError] = useState<string | null>('');

  const title = type === 'CREATE' ? 'New user' : 'Update user';

  const onSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError(null);

    const formData = new FormData(e.currentTarget);
    const data = ({
      ...Object.fromEntries(formData),
      features: [],
    } as unknown) as UserFormData;

    if (data.confirm_password !== data.password) {
      setFormError("Password doesn't match");
      return;
    }

    const exists = users.find(user => user.username === data.email);

    if (type === 'UPDATE' && exists !== userData) {
      setFormError('Another user with this email already exists');
      return;
    }

    if (type === 'CREATE' && exists) {
      setFormError('A user with this email already exists');
      return;
    }

    delete data.confirm_password;

    for (const item in data) {
      if (data[item] === 'on') {
        if (item === 'admin') {
          data[item] = true;
        } else {
          data.features = [...data.features, item];
          delete data[item];
        }
      }
    }

    if (type === 'CREATE') {
      createUserMutation.mutate({
        username: data.email,
        password: data.password,
        features: [],
        isAdmin: !!data.admin,
      });

      return;
    }

    updateUserMutation.mutate({
      id: userData.id,
      username: data.email,
      password: data.password.length > 1 ? data.password : null,
      features: [],
      isAdmin: !!data.admin,
    });
  };

  return (
    <Dialog
      onOpenChange={setDialogIsOpen}
      open={dialogIsOpen}
      aria-describedby={undefined}
    >
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent className="bg-light-3">
        <DialogHeader>
          <DialogTitle className="font-medium text-md">{title}</DialogTitle>
        </DialogHeader>
        <form
          onSubmit={onSave}
          className="flex flex-col gap-4 items-center justify-start py-2"
        >
          <div className="w-full flex flex-col gap-4 items-start relative">
            <section className="w-full">
              <Input
                className="ring-offset-0 ring-offset-transparent boder-0 border-transparent focus-visible:ring-0 focus-visible:ring-offset-0 bg-light-2 placeholder:text-xs"
                id="email"
                name="email"
                placeholder="Email"
                defaultValue={userData?.username ?? ''}
                type="email"
                autoCapitalize="none"
                autoComplete="user"
                autoCorrect="off"
                required={true}
                disabled={false}
              />
            </section>
            <section className="w-full">
              <Input
                className="ring-offset-0 ring-offset-transparent boder-0 border-transparent focus-visible:ring-0 focus-visible:ring-offset-0 bg-light-2 placeholder:text-xs"
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                autoCapitalize="none"
                autoComplete="user"
                autoCorrect="off"
                required={!userData}
                disabled={false}
              />
            </section>
            <section className="w-full">
              <Input
                className="ring-offset-0 ring-offset-transparent boder-0 border-transparent focus-visible:ring-0 focus-visible:ring-offset-0 bg-light-2 placeholder:text-xs"
                id="confirm_password"
                name="confirm_password"
                placeholder="Confirm Password"
                type="password"
                autoCapitalize="none"
                autoComplete="user"
                autoCorrect="off"
                required={!userData}
                disabled={false}
              />
            </section>

            {formError && (
              <p className="text-xs text-red-600 absolute left-1 top-full mt-1">
                {formError}
              </p>
            )}
          </div>

          <section className="flex items-center gap-2 w-fit ml-auto text-xs">
            <p className="text-dark-1">User with admin permission</p>
            <Switch
              className="data-[state=unchecked]:bg-light-1 data-[state=checked]:bg-green-500 h-4 w-[2.3rem] data-[state=checked]:shadow-inner"
              id="admin"
              name="admin"
              defaultChecked={userData?.isAdmin ?? false}
              onCheckedChange={() => console.log('checked')}
            />
          </section>

          <div className="bg-white rounded w-full p-4 flex flex-col gap-2 items-start text-start">
            <p className="text-xs font-medium">Permissions</p>
            <div className="p-3 flex flex-col gap-3">
              {clientFeatures.length === 0 && (
                <p className="text-xs text-gray-500">
                  Your company, currently has no feature active
                </p>
              )}
              {clientFeatures.map(feat => {
                if (!feat.isActive) return;
                return (
                  <div key={feat.type} className="flex items-center space-x-2">
                    <Checkbox
                      id={feat.type}
                      className="w-4 h-4"
                      name={feat.type}
                      /* @TODO: fix the defaultChecked */
                      // defaultChecked={
                      //   !!userData?.features.find(
                      //     userFeat => userFeat.type === feat.type,
                      //   )
                      // }
                    />
                    <label
                      htmlFor={feat.type}
                      className="text-xs font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {transformForInput(feat.type)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <section className="w-full flex items-center justify-between gap-4 mt-5">
            <DialogClose asChild>
              <Button
                variant="outline"
                className="w-full text-xs bg-transparent rounded-md shadow-md border border-dark-1"
                onClick={() => setFormError(null)}
                disabled={
                  createUserMutation.isPending || updateUserMutation.isPending
                }
              >
                Cancel
              </Button>
            </DialogClose>
            <Button
              type="submit"
              variant="outline"
              className="w-full text-xs bg-dark-1 text-light-3 rounded-md shadow-md border border-dark-1"
              disabled={
                createUserMutation.isPending || updateUserMutation.isPending
              }
            >
              {createUserMutation.isPending || updateUserMutation.isPending ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </section>
        </form>
      </DialogContent>
    </Dialog>
  );
};
