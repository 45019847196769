import { ChartType, ExpectedTablePayload, TestTabTable } from '@api/types';

export interface Chart {
  id: string;
  type: ChartType;
  url: string;
  code: string;
  cachedChart?: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export function transformToExpectedTablePayload(
  input: TestTabTable['fields'],
  tableId: string,
): ExpectedTablePayload {
  // Extract column names from the Record keys
  const columns = Object.keys(input);

  // Transform the fields into cells array
  const cells = columns.flatMap(columnName =>
    // For each column, map its array of contents to the cell format
    input[columnName].map(field => ({
      content: field.content,
      column: columnName,
    })),
  );

  return {
    tableId,
    columns,
    cells,
  };
}

export async function fetchWithErrorHandling<T>(
  url: string,
  options: RequestInit,
) {
  const response = await fetch(url, options);
  if (!response.ok) {
    const res = await response.json();
    throw new Error(res.error);
  }

  return (await response.json()) as T;
}
