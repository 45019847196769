import { ChartDataset } from 'chart.js';
import { Annotation, ChartStoreState } from '@app/types/chart.types.ts';

interface GenerateChartOptionsProps {
  dataset: ChartDataset<'line'>[];
  setToolTip: (toolTip: ChartStoreState['toolTip']) => void;
  showAnnotations: boolean;
  setShowAnnotations: (show: boolean) => void;
  annotations: Annotation[];
}

// const animation = {
//   duration: 20000,
//   onComplete: (ctx: { initial: boolean; currentStep: number }) => {
//     console.debug('completed animation', ctx);
//   },
//   x: {
//     type: 'number' as const,
//     easing: 'linear',
//     duration: dataset[0]?.data.length
//       ? 20000 / dataset[0].data.length
//       : 20000,
//     from: NaN,
//     delay(ctx: AnimationContext) {
//       if (ctx.type !== 'data' || ctx.xStarted) {
//         return 0;
//       }
//       ctx.xStarted = true;
//       return 20000;
//     },
//   },
//   y: {
//     type: 'number' as const,
//     easing: 'linear',
//     duration: dataset[0]?.data.length
//       ? 20000 / dataset[0].data.length
//       : 20000,
//     from: (ctx: AnimationContext) => {
//       if (ctx.index === 0) {
//         return ctx.chart.scales[ctx.dataset.yAxisID].getPixelForValue(0);
//       }
//       return ctx.chart
//         .getDatasetMeta(ctx.datasetIndex)
//         .data[ctx.index - 1]?.getProps(['y'], true).y;
//     },
//     delay(ctx: AnimationContext) {
//       if (ctx.type !== 'data' || ctx.yStarted) {
//         return 0;
//       }
//       ctx.yStarted = true;
//       return 20000;
//     },
//   },
// };

export const generateChartOptions = ({
  dataset,
  showAnnotations,
  annotations,
}: GenerateChartOptionsProps) => {
  if (dataset.length === 0) {
    return {};
  }

  const scales = generateScales(dataset);

  const mappedAnnotations = annotations.reduce((acc, annotation, index) => {
    return {
      ...acc,
      [`point${index + 1}`]: annotation,
    };
  }, {});

  const annotation = {
    annotations: mappedAnnotations,
  };

  const plugins = {
    legend: {
      position: 'top' as const,
      labels: {
        filter: (legendItem: { text: string }) => {
          // Only show legends for actual dataset
          return dataset.some(dataset => dataset.label === legendItem.text);
        },
      },
    },
    title: {
      display: true,
      text: 'Chart Title', // This could be configurable
    },
    annotation: showAnnotations ? annotation : {},
    tooltip: {
      // Keeping tooltip simple for now, we can add customization later
    },
  };

  return {
    responsive: true,
    plugins,
    // animation,
    scales,
    interaction: {
      intersect: true,
      mode: 'nearest' as const,
      axis: 'x' as const,
      threshold: 10,
    },
  };
};

function generateScales(dataset: ChartDataset<'line'>[]) {
  const scales: Record<string, any> = {
    x: {
      min: 0,
      max: 24,
      grid: {
        color: 'rgba(0, 0, 0, 0.1)',
        lineWidth: 0.5,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
        autoSkip: true,
        font: {
          size: 10,
        },
      },
    },
  };

  // Create a scale for each dataset
  dataset.forEach((dataset, index) => {
    const yAxisID = index === 0 ? 'y' : `y${index}`;
    // const values = dataset.data as number[];

    scales[yAxisID] = {
      type: 'linear',
      display: true,
      position: index === 0 ? 'left' : 'right',
      title: {
        display: true,
        text: dataset.label,
      },
      min: 0,
      max: 100,
      grid: {
        drawOnChartArea: index === 0,
      },
    };
  });

  return scales;
}
