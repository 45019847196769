import { SessionList } from './session.types.ts';
import { Features } from '@api/types/features.types.ts';

export interface User {
  id: string;
  username: string;
  type?: string;
  model?: string;
  createdAt: string;
  isActive: boolean;
  isAdmin: boolean;
  features: Features[];
  owner: string;
  sessions?: SessionList;
  charts?: ChartsList;
  prompts?: Record<string, unknown>;
}

export type ApplicationUser = Omit<User, 'sessions' | 'charts' | 'prompts'>;

enum HTMLFormCheckBoxValue {
  on = 'on',
  off = 'off',
}

export interface UserFormData {
  features: string[];
  email: string;
  password: string;
  confirm_password?: string;
  admin: HTMLFormCheckBoxValue | boolean;

  appBuilder: HTMLFormCheckBoxValue;
  dashboard: HTMLFormCheckBoxValue;
  images: HTMLFormCheckBoxValue;
  toolList: HTMLFormCheckBoxValue;

  // this is just to allow string indexing with the form data

  [key: string]: unknown;
}

export type CreateUserRes = Omit<User, 'sessions' | 'charts' | 'prompts'>;

// Payload type for creating a user
export type CreateUserPayload = Omit<
  User,
  | 'sessions'
  | 'charts'
  | 'prompts'
  | 'id'
  | 'type'
  | 'model'
  | 'createdAt'
  | 'isActive'
> & {
  features?: Features[];
  password: string;
};

// Payload type for updating a user
export type UpdateUserPayload = Partial<
  Omit<
    User,
    'sessions' | 'charts' | 'prompts' | 'type' | 'model' | 'createdAt' | 'owner'
  >
> & {
  password?: string | null;
};

// User Dashboard

export enum ChartType {
  CHART = 'CHART',
  HTML = 'HTML',
}

export type ChartsList = {
  id: string;
  user: User;
  type: ChartType;
  url: string;
  code: string;
  updatedAt: Date;
  createdAt: Date;
}[];
