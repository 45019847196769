import { API_URL } from '../configs.ts';
import { token } from '@packages/api';
import {
  CreateUserPayload,
  CreateUserRes,
  UpdateUserPayload,
  User,
} from '@api/types';
import { Chart, fetchWithErrorHandling } from '@api/utils.ts';

export class UserClient {
  public usersActives: number;
  public defaultHeaders: Record<string, string>;

  constructor(usersActives?: number, userToken?: string) {
    this.usersActives = usersActives ?? 0;
    this.defaultHeaders = {
      authorization: userToken ?? token,
      'Content-Type': 'application/json',
    };
  }

  public async findUser(id: string) {
    return await fetchWithErrorHandling<User>(`${API_URL}/user/${id}`, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  }

  public async create(payload: CreateUserPayload) {
    return await fetchWithErrorHandling<CreateUserRes>(`${API_URL}/user`, {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify({
        ...payload,
        isActive: this.usersActives < 5,
      }),
    });
  }

  public async update(payload: UpdateUserPayload): Promise<UpdateUserPayload> {
    await fetchWithErrorHandling<string>(`${API_URL}/user`, {
      headers: this.defaultHeaders,
      method: 'PATCH',
      body: JSON.stringify(payload),
    });

    return payload;
  }

  public async listUsers(owner?: string) {
    if (!owner) return null;

    return await fetchWithErrorHandling<User[]>(
      `${API_URL}/user/list/${owner}`,
      {
        method: 'GET',
        headers: this.defaultHeaders,
      },
    );
  }

  public async getDashboard(username: string) {
    return await fetchWithErrorHandling<Chart[]>(
      `${API_URL}/user/charts/${username}`,
      {
        method: 'GET',
        headers: this.defaultHeaders,
      },
    );
  }
}
