import { useChartStore } from '@app/stores';
import { ChartDataset } from 'chart.js';

export function useChart() {
  const chartStore = useChartStore();
  const { setLabels, addDataset, config } = chartStore;

  // New function to process incoming data
  const processData = (rawData: Record<string, number | string>[]) => {
    if (!rawData.length) return;

    // Set labels from x-axis field
    const labels = rawData.map(d => String(d[config.xAxis.label]));
    setLabels(labels);

    // Create datasets for each field except x-axis
    const dataKeys = Object.keys(rawData[0]).filter(
      key => key !== config.xAxis.label,
    );

    dataKeys.forEach((key, index) => {
      const colors = [
        'rgb(63, 81, 181)',
        'rgb(233, 30, 99)',
        'rgb(76, 175, 80)',
      ];
      const color = colors[index % colors.length];

      const dataset: ChartDataset<'line'> = {
        label: key,
        data: rawData.map(d => Number(d[key])),
        borderColor: color,
        tension: 0.1,
        yAxisID: 'y',
        pointRadius: 3,
        pointHoverRadius: 4,
      };

      addDataset(dataset);
    });
  };

  function addPoint() {}

  return {
    ...chartStore,
    addPoint,
    processData,
  };
}
