import { create } from 'zustand';
import { ChartDataset } from 'chart.js';
import { Annotation, ChartStoreState } from '@app/types/chart.types.ts';

interface ChartStore extends ChartStoreState {
  setLabels: (labels: string[]) => void;
  addDataset: (dataset: ChartDataset<'line'>) => void;
  setDataset: (dataset: ChartDataset<'line'>[]) => void;
  setConfig: (config: Partial<ChartStoreState['config']>) => void;
  setToolTip: (toolTip: ChartStoreState['toolTip']) => void;
  addAnnotation: (annotation: Annotation) => void;
  cleanAnnotation: () => void;
}

const initialState: ChartStoreState = {
  labels: Array.from({ length: 25 }, (_, i) => i.toString()),
  datasets: [
    {
      label: 'Default',
      data: Array.from({ length: 25 }, () => 1),
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
      yAxisID: 'y',
    },
  ],
  config: {
    xAxis: {
      min: 0,
      max: 24,
      label: 'Time (hours)',
    },
    yAxis: {
      min: 0,
      max: 100,
      label: '',
    },
  },
  toolTip: {
    show: false,
    x: 0,
    y: 0,
    content: '',
  },
  annotations: [],
};

export const useChartStore = create<ChartStore>(set => ({
  ...initialState,
  setLabels: labels => set({ labels }),
  addDataset: dataset =>
    set(state => ({
      datasets: [...state.datasets, dataset],
    })),
  setDataset: datasets => set({ datasets }),
  setConfig: config =>
    set(state => ({
      config: { ...state.config, ...config },
    })),
  setToolTip: toolTip => set({ toolTip }),
  addAnnotation: newAnnotation =>
    set(state => {
      const existingIndex = state.annotations.findIndex(
        ann => ann.xValue === newAnnotation.xValue,
      );

      // If no annotation at this X value, add new one
      if (existingIndex === -1) {
        return { annotations: [...state.annotations, newAnnotation] };
      }

      // If annotation exists at same X value but different Y, replace it
      if (state.annotations[existingIndex].yValue !== newAnnotation.yValue) {
        const newAnnotations = [...state.annotations];
        newAnnotations[existingIndex] = newAnnotation;
        return { annotations: newAnnotations };
      }

      // If same, don't add
      return state;
    }),
  cleanAnnotation: () => set({ annotations: [] }),
}));
