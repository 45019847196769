import { createContext, useMemo, useState } from 'react';
import { FEATURE_TYPE, Features } from '@packages/api';

// @TODO: Refactor this, get the new features, change API payload and response, then update this context, also changing all features to inside of a dic
export interface IFeaturesContext {
  features: Features[];
  setFeatures: (features: Features[]) => void;
  enableAppBuilder: boolean;
  enableToolCreator: boolean;
  enableInsights: boolean;
  enableDashboard: boolean;
  enableToolsList: boolean;
}

export const FeaturesContext = createContext<IFeaturesContext>({
  features: [],
  setFeatures: (_features: Features[]) => null,
  enableAppBuilder: true,
  enableToolCreator: true,
  enableInsights: true,
  enableDashboard: true,
  enableToolsList: true,
});

export const FeaturesProvider = ({ children }: { children: any }) => {
  const [features, setFeatures] = useState<Features[]>([]);

  const featuresValidation = useMemo(() => {
    return {
      enableAppBuilder: !!features.find(
        feat => feat?.type === FEATURE_TYPE.APP_BUILDER,
      ),
      enableToolCreator: !!features.find(
        feat => feat?.type === FEATURE_TYPE.TOOL_CREATOR,
      ),
      enableInsights: !!features.find(
        feat => feat?.type === FEATURE_TYPE.INSIGHTS,
      ),
      enableDashboard: !!features.find(
        feat => feat?.type === FEATURE_TYPE.DASHBOARD,
      ),
      enableToolsList: !!features.find(
        feat => feat?.type === FEATURE_TYPE.TOOLS,
      ),
    };
  }, [features]);

  const contextValue = useMemo(
    () => ({
      features,
      setFeatures,
      ...featuresValidation,
    }),
    [features],
  );

  return (
    <FeaturesContext.Provider value={contextValue}>
      {children}
    </FeaturesContext.Provider>
  );
};
