import { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import ManagementStore from '@management/stores/ManagementStore.ts';
import { removeEmptyFieldsFromObject } from '@management/utils/removeEmptyFieldsFromObject.ts';
import {
  ClientClient,
  CreateUserPayload,
  CreateUserRes,
  UpdateClientPayload,
  UpdateUserPayload,
  UserClient,
} from '@packages/api';
import {
  defaultLightToastOptions,
  defaultToastErrorToastOptions,
} from '@packages/ui';
import { useAuth } from '@packages/hooks';
import { useShallow } from 'zustand/react/shallow';
import { useClientStore } from '@management/stores';
import { queryClient } from '@management/utils/QueryClient.ts';

export function useAccManagement() {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const { currentUser } = useAuth();

  const { setUsers, users } = ManagementStore(
    useShallow(state => ({
      setUsers: state.setUsers,
      users: state.users,
    })),
  );
  const { features, setFeatures } = useClientStore(
    useShallow(state => ({
      features: state.features,
      setFeatures: state.setFeatures,
    })),
  );

  const usersActives = useMemo(() => {
    if (!users || users.length === 0) return 0;
    return users.filter(user => user.isActive).length;
  }, [users]);

  const userApi = new UserClient(usersActives, currentUser!.sessionToken);
  const clientAPI = new ClientClient(currentUser!.sessionToken);

  const { data: userList, isLoading, error } = useQuery(
    {
      queryKey: ['userList', currentUser?.owner],
      queryFn: () => userApi.listUsers(currentUser!.owner),
      refetchOnMount: false,
      retryOnMount: false,
      enabled: !!currentUser?.owner,
    },
    queryClient,
  );

  useEffect(() => {
    if (userList && userList !== users) {
      setUsers(userList);
    }
    if (error) {
      toast.error(error.message, defaultToastErrorToastOptions);
    }
  }, [userList, error]);

  const createUserMutation = useMutation(
    {
      mutationFn: (payload: Omit<CreateUserPayload, 'owner'>) =>
        userApi.create({ ...payload, owner: currentUser!.owner }),
      onSuccess: (data: CreateUserRes) => {
        void queryClient.invalidateQueries({
          queryKey: ['userList'],
        });
        setUsers([
          ...users,
          {
            id: data.id,
            isActive: data.isActive,
            createdAt: data.createdAt,
            username: data.username,
            features: data?.features ?? [],
            isAdmin: data.isAdmin,
            owner: currentUser!.owner,
          },
        ]);
        toast.success('User Created!', defaultLightToastOptions);
        setDialogIsOpen(false);
      },
      onError: error => {
        toast.error(error.message, defaultToastErrorToastOptions);
      },
    },
    queryClient,
  );

  const updateUserMutation = useMutation(
    {
      mutationFn: (payload: UpdateUserPayload) =>
        userApi.update(
          removeEmptyFieldsFromObject(payload) as UpdateUserPayload,
        ),
      onSuccess: data => {
        setUsers(
          users.map(user =>
            user.id === data.id
              ? {
                  ...user,
                  ...data,
                }
              : user,
          ),
        );
        toast.success('User Updated!', defaultLightToastOptions);
      },
      onError: error => {
        toast.error(error.message, defaultToastErrorToastOptions);
      },
    },
    queryClient,
  );

  const updateClientMutation = useMutation(
    {
      mutationFn: (payload: UpdateClientPayload) =>
        clientAPI.update({
          id: currentUser!.owner,
          ...payload,
        }),
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['ownerInfo'],
        });

        toast.success('Client Database Updated!', defaultLightToastOptions);
      },
      onError: error => {
        toast.error(error.message, defaultToastErrorToastOptions);
      },
    },
    queryClient,
  );

  const switchUserState = (id: string) => {
    const user = users.find(user => user.id === id);

    if (!user) return;

    if (usersActives >= 5 && !user.isActive) return;

    setUsers(
      users.map(obj =>
        obj.id === id ? { ...obj, isActive: !obj.isActive } : obj,
      ),
    );

    userApi
      .update({
        id,
        isActive: !user.isActive,
      } as UpdateUserPayload)
      .then(res => console.log(res));
  };

  return {
    users,
    switchUserState,
    clientFeatures: features,
    setClientFeatures: setFeatures,
    isLoadingUserList: isLoading,
    usersActives,
    setUsers,
    createUserMutation,
    updateUserMutation,
    dialogIsOpen,
    setDialogIsOpen,
    updateClientMutation,
  };
}
