import {
  FlowChartEdge,
  FlowChartNode,
  FlowChartStep,
} from '@app/types/flowchart.types.ts';
import { feedback } from '@packages/api';

export interface VisibleTables {
  initial: boolean;
  expected: boolean;
}

export interface VisibleTestCases {
  pass: boolean;
  fail: boolean;
}

export interface VisualizationMode {
  cases: boolean;
  table: boolean;
}

export enum VisualizationModeEnum {
  CASES = 'cases',
  TABLE = 'table',
}

export interface UIStateStore {
  flowChartStep: FlowChartStep | null;
  setFlowChartStep: (step: FlowChartStep) => void;
  isCodeEnabled: boolean;
  setIsCodeEnabled: (isCodeEnabled: boolean) => void;
  currentFeedback: string | null;
  setCurrentFeedback: (feedback: string | null) => void;
  visibleTables: VisibleTables;
  setVisibleTables: (visibleTables: VisibleTables) => void;
  visibleTestCases: VisibleTestCases;
  setVisibleTestCases: (visibleTestCases: VisibleTestCases) => void;
  selectedColumns: number[];
  setSelectedColumns: (selectedColumns: number[]) => void;
  visualizationMode: VisualizationMode;
  visualizationModeSwitch: (mode: VisualizationModeEnum) => void;
  objectivesChangesMade: string[];
  addObjectiveChange: (change: string) => void;
  setObjectivesChange: (changes: string[]) => void;
  showAnnotations: boolean;
  setShowAnnotations: (show: boolean) => void;
}

interface TextWithFeedback {
  content: string;
  feedback?: feedback;
  updatedAt: number;
}

export interface RoutineData {
  flowChart: {
    nodes: FlowChartNode[];
    edges: FlowChartEdge[];
  };
  id: string;
  code: TextWithFeedback | null;
  review: TextWithFeedback | null;
  updatedAt: number | null;
  name: string | null;
  // status: RoutineStatus;
}

export interface RoutinesList {
  id: string;
  name: string;
  updatedAt: number;
}
